import { useNavigate } from "react-router-dom";
import { TransactionTypeBadge } from "../Components/TransactionTypeBadge";
import { OrderStatusBadge } from "../Components/OrderStatusBadge";
import s from "../style.module.css";
import { Card } from "react-bootstrap";
import { VehicleTypeBadge } from "../../Vehicles/VehicleTypeBadge";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { TransportTypeBadge } from "../Components/TransportTypeBadge";
import { DomainPagination } from "../../Abstractions/DomainPagination";
import { CardLinkBody } from "../../Abstractions/CardLinkBody";
import { WasteExchangesView } from "../../Orders/WasteDetails/WasteExchangesView";
import { ButtonOverlay } from "../../Abstractions/ButtonOverlay";
import { Telephone } from "react-bootstrap-icons";
import { OrderKpoCardItemsView } from "../OrderCalendar/OrderKpoCardItemsView";
import { TRANSACTION_TYPE } from "../../../domain/TransactionType";
import { PICKUP_TYPES } from "../../../domain/VehicleType";
import { OrderSeriesBadge } from "../Components/OrderSeriesBadge";

export function OrderListViewTable({ items, paging, handlePageChange }) {
  const Pagination = paging && (
    <DomainPagination
      onPageChange={handlePageChange}
      paging={paging}
      itemsCount={items.length}
    />);

  return <div className="d-flex flex-column">
    {items?.map(item => <OrderListViewItem key={item.id} orderItem={item}/>)}
    {Pagination}
  </div>;
}

export const OrderListViewItem = ({ orderItem }) => {
  const { id, pickupType, transportType, vehicleName, includedVehicleName, includedVehicleRegNumber, isTransfer, orderNumber,
    transactionType, contractorName, remarks, orderDate, driverName,
    vehicleRegNumber, status, contractorRemarks, addressRemarks,
    driverPhone, contactPersonInfo, kpoCardItems, delayState,
    addressName, transportContractorName, externalOrderNumber,
    freightForwarderContractorName, filterShippingDate, isDeleted, isSeries } = orderItem;
  const navigate = useNavigate();
  let additionalRemarks = null;

  if (contractorRemarks) {
    additionalRemarks = `Kontrahent: ${contractorRemarks}`;
  }
  if (additionalRemarks) {
    const additionalAddress = `Adres: ${addressRemarks}`;
    additionalRemarks = additionalRemarks ? `${additionalRemarks} / ${additionalAddress}` : additionalRemarks;
  }
  let vehicleInfo = "";
  if (vehicleName || includedVehicleName) {
    vehicleInfo = `${vehicleName ?? "-"} / ${includedVehicleName ?? "-"}`;
  } else if (vehicleRegNumber || includedVehicleRegNumber) {
    vehicleInfo = `${vehicleRegNumber ?? "-"} / ${includedVehicleRegNumber ?? "-"}`;
  } else {
    vehicleInfo = "Nieprzypisany transport";
  }

  const contactPerson = contactPersonInfo?.split("-");
  const contactPersonName = contactPerson?.[0];
  const contactPersonPhone = contactPerson?.length > 1 ? contactPerson?.[1] : null;

  const navigateToDetails = () => navigate(`/orders/${id}`);

  const orderStyle = `order-${delayState.toLowerCase()}-delay`;
  const orderStatus = `order-${status.toLowerCase()}`;

  return <>
    <Card className={`${s.listItemBox} ${s[orderStyle]} ${s[orderStatus]}`}>
      <Card.Header as={`b`} className={s.header} onClick={navigateToDetails}>
        {orderNumber} - {contractorName} - {addressName}
      </Card.Header>
      <CardLinkBody link={`/orders/${id}`}>
        <div className="d-flex justify-content-start gap-1 align-items-start mt-2">
          <TransactionTypeBadge className="mb-2" transactionType={transactionType} size="smaller"/>
          {!isDeleted ?  <OrderStatusBadge className="mb-2" status={status} size="smaller"/> : <BadgeDomain bg="danger" size="smaller">Usunięte</BadgeDomain>}
          <VehicleTypeBadge type={pickupType} size="smaller"/>
          <TransportTypeBadge size="smaller" transportType={transportType}/>
          {isSeries && <OrderSeriesBadge size="smaller" className=""/>}
          <p className={s.smallListItem}>Nr. zamówienia przejmującego: <b className="upper">{externalOrderNumber ?? "-"}</b></p>
        </div>
        <div className="d-flex start upper gap-3">
          <p className={s.smallListItem}>Data transportu: <b>{orderDate}</b></p>
          {transactionType !== TRANSACTION_TYPE.TRANSPORT && pickupType === PICKUP_TYPES.TIR &&
            <p className={s.smallListItem}>Data awizacji: <b>{filterShippingDate ?? "-"}</b></p>}
          <p className={s.smallListItem}>Klient: <b>{contactPersonName ?? "-"}</b> {contactPersonPhone && <ButtonOverlay variant="success" size="xx-small" tooltip={<>Kontakt do klienta: {contactPersonPhone}</>}><Telephone/></ButtonOverlay>}</p>
          <p className={s.smallListItem}>Kierowca: <b>{driverName ?? "-"}</b> {driverPhone && <ButtonOverlay size="xx-small" tooltip={<>Telefon do kierowcy: {driverPhone}</>}><Telephone/></ButtonOverlay>}</p>
          <p className={s.smallListItem}>Pojazdy: <b>{vehicleInfo}</b></p>
          {transportContractorName && <p className={s.smallListItem}>Przewoźnik: <b>{transportContractorName ?? "-"}</b></p>}
          {freightForwarderContractorName && <p className={s.smallListItem}>Spedycja: <b>{freightForwarderContractorName ?? "-"}</b></p>}
        </div>
        <div className="d-flex justify-content-start align-items-center gap-2">
          <OrderKpoCardItemsView items={kpoCardItems}/>
          <WasteExchangesView isLoading={orderItem?.isLoading} isContainersExchange={orderItem?.isContainersExchange} wastesExchanges={orderItem?.wasteExchanges} horizontalView/>
          <p className={s.smallListItem}>{additionalRemarks}</p>
          {isTransfer && <BadgeDomain size="xx-small">Przerzut</BadgeDomain>}
        </div>
        <div className="pt-1">
          <p className={s.remarks}>{remarks}</p>
        </div>
      </CardLinkBody>
    </Card>
  </>;
};