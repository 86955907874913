import { mapToRequestWastes, mapToWastesDetails } from "./orderActionsMapper";

export function mapToCreatePurchaseRequest(payload) {
  const transportDetails = mapToTransportDetails(payload);
  const wastesDetails = mapToWastesDetails(payload.wastesDetails);

  const kpoCards = mapToRequestKpoCards(payload.bdoDetails?.kpoCards);
  const bdoDetails = { kpoCards: kpoCards ?? [] };
  const mainDetails = mapToMainDetails(payload);

  return {
    mainDetails: mainDetails,
    transportDetails: transportDetails,
    wastesDetails: wastesDetails,
    bdoDetails: bdoDetails
  };
}

export function mapToCreateSaleRequest(payload) {
  const transportDetails = mapToTransportDetails(payload);
  const wastes = mapToRequestWastes(payload.wastesDetails.saleWastes?.wastes);
  const mainDetails = mapToMainDetails(payload);
  const wastesDetails = {
    wasteInfo: wastes,
    deliveryNoteNumber: payload.wastesDetails.deliveryNoteNumber,
    saleDocumentNumber: payload.wastesDetails.saleDocumentNumber,
    generateKpoCards: payload.wastesDetails.generateKpoCards
  }

  return {
    mainDetails: mainDetails,
    transportDetails: transportDetails,
    wastesDetails: wastesDetails
  };
}

export function mapToCreateTransportRequest(payload) {
  const transportDetails = mapToTransportDetails(payload);
  const kpoCards = mapToRequestKpoCards(payload.bdoDetails?.kpoCards);
  const bdoDetails = { kpoCards: kpoCards ?? [] };
  const mainDetails = mapToMainDetails(payload);
  const wastes = mapToRequestWastes(payload.wastesDetails?.transportWastes?.wastes);
  const wastesDetails = {
    isWasteTransport: payload.wastesDetails?.isWasteTransport,
    wasteInfo: wastes,
    receivedNoteNumber: payload.wastesDetails?.receivedNoteNumber,
    deliveryNoteNumber: payload.wastesDetails?.deliveryNoteNumber,
    saleDocumentNumber: payload.wastesDetails?.saleDocumentNumber
  }

  return {
    mainDetails: mainDetails,
    transportDetails: transportDetails,
    bdoDetails: bdoDetails,
    wastesDetails: wastesDetails
  };
}

export function mapToCreateSeriesRequest(payload) {
  const seriesDetails = mapToSeriesDetails(payload);
  const mainDetails = mapToMainDetails(payload);
  const transportDetails = mapToTransportDetails(payload);
  const wastesDetails = mapToWastesDetails(payload.wastesDetails);

  return {
    seriesDetails: seriesDetails,
    mainDetails: mainDetails,
    transportDetails: transportDetails,
    wastesDetails: wastesDetails,
  };
}

export const mapToMainDetails = (payload) => {
  const contractorData = getContractorData(payload);

  return {
    ...contractorData,
    transactionType: payload.transactionType,
    contactPersonId: getValueOrNullIfEmpty(payload.orderAddress.contactPersonId),
    intermediaryId: getValueOrNullIfEmpty(payload.intermediaryId),
    remarks: payload.remarks,
    isBdoIntegrated: payload.wastesDetails.isBdoIntegrated,
    externalOrderNumber: payload.externalOrderNumber,
    salesRepresentativeId: payload.contractor.isSalesRepresentativeLinked ? payload.contractor.salesRepresentativeId : null
  };
};

export const mapToTransportDetails = (payload) => {
  let vehicleData = {};
  if (payload.transportDetails.transportType === "Internal") {
    vehicleData = {
      driverId: payload.transportDetails.driverId?.id,
      vehicleId: payload.transportDetails.vehicleId?.id,
      vehicleRegNumber: payload.transportDetails.vehicleId?.registrationNumber,
      includedVehicleId: payload.transportDetails.includedVehicleId?.id,
      includedVehicleRegNumber: payload.transportDetails.includedVehicleId?.registrationNumber,
      includedVehicleType: getValueOrNullIfEmpty(payload.transportDetails.includedVehicleId?.semiTrailerType),
      driverPhone: payload.transportDetails.driverId?.phone
    };
  } else {
    vehicleData = {
      externalDriver: payload.transportDetails.externalDriver,
      vehicleRegNumber: payload.transportDetails.vehicleRegNumber,
      includedVehicleRegNumber: payload.transportDetails.includedVehicleRegNumber,
      includedVehicleType: getValueOrNullIfEmpty(payload.transportDetails.externalIncludedVehicleType),
      contractorTransportId: payload.transportDetails.transportContractor?.id,
      isFreightForwarder: payload.transportDetails.isFreightForwarder,
      freightForwarderId: payload.transportDetails.isFreightForwarder ? payload.transportDetails.freightForwarder?.id : null,
      driverPhone: payload.transportDetails.externalDriverPhone
    };
  }

  if (payload.transportDetails.pickupType === "Tir") {
    vehicleData = {
      ...vehicleData,
      shippingDate: getValueOrNullIfEmpty(payload.transportDetails.shippingDate),
      plannedShippingTimeStart: payload.transportDetails.plannedShippingTimeStart,
      plannedShippingTimeEnd: payload.transportDetails.plannedShippingTimeEnd,
      squarePlaceType: payload.transportDetails.squarePlaceType
    };
  }

  if (payload.transportDetails.isTransportInvoiceNeeded) {
    vehicleData = {
      ...vehicleData,
      transportInvoiceNumber: payload.transportDetails.transportInvoiceNumber,
      transportInvoiceAmount: payload.transportDetails.transportInvoiceAmount !== "" ? payload.transportDetails.transportInvoiceAmount : null,
      transportInvoiceType: payload.transportDetails.invoiceTransportType
    };
  }

  if (payload.transactionType === "Sale" && payload.transportDetails.isTransfer) {
    vehicleData = {
      ...vehicleData,
      isFullyTransfer: payload.transportDetails.isTransfer ? payload.transportDetails.isFullyTransfer : null,
      transferPurchasesIds: payload.transportDetails.isTransfer ?
        payload.transportDetails.transferPurchases?.map(x => x.id) : null
    };
  }

  return {
    plannedLoadingDate: payload.transportDetails.plannedLoadingDate,
    realLoadingDate: payload.transportDetails.isPlannedLoadingDateChange ?
      payload.transportDetails.plannedLoadingDate :
      payload.transportDetails.realLoadingDate,
    plannedReloadingDate: payload.transportDetails.plannedReloadingDate,
    transportType: payload.transportDetails.transportType,
    pickupType: payload.transportDetails.pickupType,
    isTransfer: payload.transportDetails.isTransfer,
    ...vehicleData,
    isTransportInvoiceNeeded: payload.transportDetails.isTransportInvoiceNeeded
  };
};

const mapToRequestKpoCards = (kpoCards) => {
  return kpoCards?.map((x) => ({
    kpoId: x.kpoId,
    cardNumber: x.cardNumber,
    wasteCodeId: x.wasteCodeId,
    wasteCode: x.wasteCode,
    wasteMass: x.wasteMass,
    revisedWasteMass: x.correctedWasteMass,
    status: x.status
    // plannedTransportTime: x.plannedTransportTime,
    // realTransportTime: x.realTransportTime,
    // vehicleRegNumber: x.vehicleRegNumber,
    // isRevised: x.revisionNumber !== 0
  }));
};

const getContractorData = (payload) => {
  let contractorData;
  if (payload.transactionType === "Purchase" || payload.transactionType === "Transport") {
    contractorData = {
      senderContractorId: payload.contractor.id,
      senderAddressId: payload.orderAddress.id
    };
  }

  if (payload.transactionType === "Sale") {
    contractorData = {
      receiverContractorId: payload.contractor?.id,
      receiverAddressId: payload.orderAddress?.id
    };
  }

  if (payload.transactionType === "Transport") {
    contractorData = {
      ...contractorData,
      receiverAddressId: payload.receiverOrderAddress?.id,
      receiverContractorId: payload.receiverContractor?.id
    };
  }

  return contractorData;
};

export const mapToSeriesDetails = (payload) => ({
  name: payload.name,
  seriesType: payload.frequencyType,
  frequencyInterval: payload.frequencyInterval,
  dateFrom: payload.dateStart,
  dateTo: getValueOrNullIfEmpty(payload.dateEnd),
  weeklyDetails: payload.weeklyData
});

const getValueOrNullIfEmpty = (value) => value !== "" ? value : null;