import { StatusBadge } from "_components/Abstractions/StatusBadge";
import { driversActions } from "_store/drivers.slice";
import { DRIVER_TYPES } from "domain/DriverType";
import { useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { ChangeAccountState } from "useCases/accounts/ChangeAccountState";
import { AssignVehiclesToDriver } from "useCases/drivers/AssignVehiclesToDriver";
import { UpdateDriver } from "useCases/drivers/UpdateDriver";
import { SortedTh } from "../Abstractions/SortedTh";

export function DriverTable({ drivers, sorting, itemActions }) {
  const [activeDriverId, setActiveDriverId] = useState();
  const [activeAction, setActiveAction] = useState();
  const columnsNames = {
    "displayName": "Nazwa",
    "login": "Login",
    "name": "Imię",
    "lastName": "Nazwisko",
  }

  return (
    <Table hover>
      <thead>
        <tr>
          {Object.entries(columnsNames).map(([value, name]) =>
            <SortedTh key={value} sorting={sorting} itemActions={itemActions} sortingValue={value}>{name}</SortedTh>)}
          <th>Telefon</th>
          <th>Typ kierowcy</th>
          <th>Status</th>
          <th>Akcje</th>
        </tr>
      </thead>
      <tbody>
        {drivers.loading && (
          <>
            <tr className="spinner-border"></tr>
          </>
        )}
        {drivers.length > 0 &&
          drivers.map((item) => (
            <tr key={item.id} onClick={() => setActiveDriverId(item.id)}>
              {/* <td>{item.id}</td> */}
              <td>{item.displayName}</td>
              <td>{item.login}</td>
              <td>{item.name}</td>
              <td>{item.lastName}</td>
              <td>{item.phone}</td>
              <td>
                <Badge
                  bg={`${item.driverType === "Hook" ? "primary" : "dark"}`}
                >
                  {DRIVER_TYPES[item.driverType]}
                </Badge>
              </td>
              <td>
                <StatusBadge isActive={item.isActive} />
              </td>
              <td>
                <UpdateDriver
                  driver={item}
                  onShow={() => setActiveAction("updateDriver")}
                />
                <AssignVehiclesToDriver
                  driver={item}
                  activeDriverId={activeDriverId}
                  onShow={() => setActiveAction("assignVehicles")}
                  isFormActive={activeAction === "assignVehicles"}
                />
                <ChangeAccountState
                  user={item}
                  actions={driversActions}
                  onShow={() => setActiveAction("changeState")}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
